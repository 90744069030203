<template>
  <CRow>
    <CCol sm="12">
      <CToaster :autohide="3000">
        <template v-for="(toast, index) in toasts">
          <CToast
            class="text-white"
            v-bind:class="'bg-' + toast.level"
            :key="'toast' + index"
            :show="true"
          >
            {{ toast.message }}
          </CToast>
        </template>
      </CToaster>
      <CCard>
        <CCardHeader> <CIcon name="cil-notes" /> OC確定処理 </CCardHeader>
        <CForm>
          <CCardBody>
            <CAlert v-show="!closable" color="danger" closeButton>
              未確定のユーザーが存在するため、確定できません
            </CAlert>
            <CRow>
              <CCol lg="3">
                <CSelect
                  :options="closingYearMonths"
                  v-model="ocClosing.year_month"
                  @update:value="changeYearMonth($event)"
                  prepend="確定年月"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>確定状況</small><br />
                  <strong class="h5">
                    <span v-show="!closed" class="text-danger font-weight-bold">未確定</span>
                    <span v-show="closed" class="text-primary font-weight-bold">確定済</span>
                  </strong>
                </CCallout>
              </CCol>
              <CCol col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>月次使用枚数</small><br />
                  <strong class="h5">{{ ocClosing.purchaseQuantity }}</strong>
                  <small>&nbsp;枚</small>
                </CCallout>
              </CCol>
              <CCol col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>月次割引金額</small><br />
                  <strong class="h5">{{ formatPrice(ocClosing.couponPrice) }}</strong>
                  <small>&nbsp;円</small>
                </CCallout>
              </CCol>
              <CCol col="12" sm="3" md="3" lg="2" style="margin-top: 20px">
                <CButton
                  color="primary"
                  size="lg"
                  style="width: 150px"
                  :disabled="!closable"
                  @click="closed ? ocRelease() : ocClose()"
                >
                  {{ closed ? "確定解除" : "確定" }}
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CForm>
      </CCard>

      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid" />
          日次履歴
        </CCardHeader>
        <CCardBody>
          <CDataTable :items="closingUsers" :fields="fields" sorter>
            <template #userName="{ item }">
              <td class="text-left">
                {{ item.userName }}
              </td>
            </template>

            <template #userRole="{ item }">
              <td class="text-center">{{ item.userRole }}</td>
            </template>

            <template #purchaseQuantity="{ item }">
              <td class="text-right">{{ item.purchaseQuantity }}枚</td>
            </template>

            <template #couponPrice="{ item }">
              <td class="text-right">{{ formatPrice(item.couponPrice) }}円</td>
            </template>

            <template #status="{ item }">
              <td class="text-center">
                <span v-show="item.userClosing == null || !item.userClosing.closed" class="text-danger">{{ item.status }}</span>
                <span v-show="item.userClosing != null && item.userClosing.closed" class="text-primary">{{ item.status }}</span>
              </td>
            </template>

            <template #note="{ item }">
              <td class="text-left">{{ item.note }}</td>
            </template>

            <template #userRelease="{ item }">
              <td class="text-center">
                <CButton
                    color="primary"
                    shape="square"
                    size="sm"
                    v-show="!closed && item.userClosing != null && item.userClosing.closed"
                    @click="userRelease(item.user.id)"
                  >
                  解除
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import firebase from "@firebase/app";
import { CouponStatus, UserRole, ServiceStartDate } from "@/common/const";

const fields = [
  { key: "userName", label: "ユーザー", _style: "width:20%; text-align:center;", },
  { key: "userRole", label: "ユーザー種別", _style: "width:10%; text-align:center;", },
  { key: "purchaseQuantity", label: "枚数", _style: "width:10%; text-align:center;", },
  { key: "couponPrice", label: "割引金額", _style: "width:15%; text-align:center;", },
  { key: "status", label: "確定状況", _style: "width:10%; text-align:center;", },
  { key: "note", label: "申し送り事項", _style: "width:25%; text-align:center;", },
  { key: "userRelease", label: "", _style: "width:10%; text-align:center;", sorter: false},
];

export default {
  data() {
    return {
      closingUsers: [],
      ocClosing: {
        purchaseQuantity: 0,
        couponPrice: 0,
      },
      closingYearMonths: [],
      fields,
      closable: false,
      closed: false,
      toasts: [],
    };
  },
  methods: {
    async init() {
      // 初期化
      this.$store.commit("showLoading")
      this.setYearMonth();
      await this.fetchOcClosing();
      await this.fetchClosing();
      this.$store.commit("hideLoading")
    },

    setYearMonth() {
      const now = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }))
      let startDay = new Date(ServiceStartDate.getFullYear(), ServiceStartDate.getMonth(), 1)
      let endDay = new Date(now.getFullYear(), now.getMonth() + 1, 1)
      do {
        this.closingYearMonths.push({
          label: `${startDay.getFullYear()}/${(startDay.getMonth() + 1).toString().padStart(2, "0") }`,
          value: this.toYearMonth(startDay),
        });
        startDay.setMonth(startDay.getMonth() + 1);
      } while (startDay.getTime() !== endDay.getTime());
      this.ocClosing.year_month = this.toYearMonth(now);
    },

    async fetchOcClosing() {
      this.closed = false
      const ocClosingSnapShot = await firebase.firestore()
        .collection("ocClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get();
      ocClosingSnapShot.forEach(doc => {
        this.ocClosing = doc.data();
        this.closed = true
      })
    },

    async fetchClosing() {
      const firebaseUser = firebase.auth().currentUser;
      // 未ログイン時はなにもしない
      if (firebaseUser == null) {
        return;
      }

      const users = await firebase.firestore().collection("users").orderBy("name").get()
      const usages = await firebase.firestore().collection("usages").where("usageTime", ">=", this.ocClosing.year_month * 100000000).get()
      const userClosings = await firebase.firestore().collection("userClosings").get()
      const yearMonthUsages = usages.docs
        .map(doc => doc.data())
        .filter(data => parseInt(data.usageTime?.toString().slice(0, 6)) == this.ocClosing.year_month)

      this.closingUsers = []
      this.ocClosing.purchaseQuantity = 0
      this.ocClosing.couponPrice = 0
      this.closable = true
      for (const userDoc of users.docs) {
        const user = userDoc.data()

        let userUsages = []
        if (user.role === UserRole.DISTRIBUTIONS) {
          // 小売
          userUsages = yearMonthUsages.filter(usage => usage.companyCode === ("000000" + user.companyCode).slice(-6))
        } else if (user.role === UserRole.MAKERS) {
          // メーカー
          userUsages = yearMonthUsages.filter(usage => usage.maker_id === user.id)
        } else if (user.role === UserRole.WHOLESALE) {
          // 卸
          userUsages = yearMonthUsages.filter(usage => usage.wholesale_id === user.id)
        }

        if (userUsages.length > 0) {
          const userClosing = userClosings.docs.map(doc => doc.data())
            .find(closing => closing.user.path == 'users/' + userDoc.id && closing.year_month == this.ocClosing.year_month)
          const closingUser = {
            userName: user.name,
            userRole: UserRole.getName(user.role),
            purchaseQuantity: userClosing == null || !userClosing.closed ? userUsages.reduce((sum, usage) => sum + (usage.purchaseQuantity  || 0), 0) : userClosing.purchaseQuantity,
            couponPrice: userClosing == null || !userClosing.closed ? userUsages.reduce((sum, usage) => sum + (usage.couponPrice  || 0), 0) : userClosing.couponPrice,
            status: userClosing == null || !userClosing.closed ? '未確定' : '確定済',
            note: userClosing == null ? '' : userClosing.note,
            user: user,
            usages: userUsages,
            userClosing: userClosing,
          }
          if (user.role === UserRole.DISTRIBUTIONS) {
            //　小売分のみ（メーカー、卸は小売と重複のため）
            this.ocClosing.purchaseQuantity += closingUser.purchaseQuantity || 0
            this.ocClosing.couponPrice += closingUser.couponPrice || 0
          }
          if (closingUser.userClosing == null || !closingUser.userClosing.closed) {
            this.closable = false
          }
          this.closingUsers.push(closingUser)
        } 
      }
    },

    async changeYearMonth(value) {
      this.ocClosing.year_month = value;
      this.$store.commit("showLoading")
      await this.fetchOcClosing();
      await this.fetchClosing();
      this.$store.commit("hideLoading")
    },

    async validOcClose() {
      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get();

      if (!userClosingSnapShot.docs.every((closing) => closing.data().closed)) {
        this.toasts.push({ level: "danger", message: "未確定のユーザーが存在します" });
        return false;
      }

      const ocClosingSnapShot = await firebase.firestore()
        .collection("ocClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get();

      if (ocClosingSnapShot.docs.length > 0) {
        this.toasts.push({level: "danger", message: "すでに確定済みです。"});
        return false;
      }

      return true
    },
    async ocClose() {
      if (!await this.validOcClose()) return false

      const batch = firebase.firestore().batch()

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get()

      let userClosings = []
      userClosingSnapShot.forEach(doc => userClosings.push(doc.ref))
      this.ocClosing.userClosings = userClosings
      this.ocClosing.closed_at = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }))

      const ocClosingRef = await firebase.firestore()
        .collection("ocClosings")
        .doc();
      batch.set(ocClosingRef, this.ocClosing)

      await batch.commit()
      this.closed = true
      this.toasts.push({ level: "success", message: "確定しました。" });
    },

    async validOcRelease() {
      if (!confirm("確定を解除します。よろしいですか？")) return

      const ocClosingSnapShot = await firebase.firestore()
        .collection("ocClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get();

      if (ocClosingSnapShot.docs.length === 0) {
        this.toasts.push({ level: "danger", message: "確定されていません。" });
        return false
      }

      return true
    },
    async ocRelease() {
      if (!await this.validOcRelease()) return false

      const batch = firebase.firestore().batch()

      const ocClosingSnapShot = await firebase.firestore()
        .collection("ocClosings")
        .where("year_month", "==", this.ocClosing.year_month)
        .get()

      ocClosingSnapShot.forEach(doc => {
        batch.delete(doc.ref)
      })

      await batch.commit()
      this.closed = false
      this.toasts.push({ level: "success", message: "確定解除しました。" })
    },

    async userRelease(userId) {
      const closingUser = this.closingUsers.find(u => u.user.id === userId)
      if (closingUser == null) return

      let userClosing = closingUser.userClosing
      if (userClosing == null) return

      const batch = firebase.firestore().batch()

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("year_month", "==", userClosing.year_month)
        .where("user", "==", userClosing.user)
        .get()

      userClosingSnapShot.forEach(doc => {
        userClosing = doc.data()
        userClosing.usages = null;
        userClosing.closed = false
        userClosing.closed_at = null
        batch.set(doc.ref, userClosing)
      })

      await batch.commit()

      this.closingUsers.forEach(c => {
        if (c.user.id === userId) {
          c.status = '未確定'
          c.userClosing = userClosing
        }
      })
      this.toasts.push({ level: "success", message: "確定解除しました。" })
    },

    groupBy(array, getKey) {
      return Array.from(
        array.reduce((map, cur, idx, src) => {
          const key = getKey(cur, idx, src);
          const list = map.get(key);
          if (list) list.push(cur);
          else map.set(key, [cur]);
          return map;
        }, new Map())
      );
    },
    
    toYearMonth(date) {
      if (date == null) return null;
      return parseInt(date.getFullYear().toString() + ("00" + (date.getMonth() + 1)).slice(-2))
    },
    formatPrice(price) {
      return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
};
</script>
